import React from 'react'
import PropTypes from 'prop-types'

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <ul className="icons">

    </ul>
    <p className="copyright">&copy; Cameron Smith &amp; CWS Development LLC.</p>
    <p className="attribution">
      Background image by <strong>Shubham Kumar</strong>, via{' '}
      <a href="https://unsplash.com">Unsplash</a>
    </p>
  </footer>
)

Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer
