import PropTypes from 'prop-types'
import React from 'react'
import ssProfile from '../images/ss_profile.jpg'
import gsProfile from '../images/gs_profile.jpg'

const urlPrefix = process.env.NODE_ENV === 'development'
	? 'localhost:3000'
	: ''

class Main extends React.Component {
	onComponentWillMount() {
		fetch(urlPrefix + '/api/notify', {
			headers: { cache: 0 },
			method: 'POST'
		})
	}
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="icc"
          className={`${this.props.article === 'icc' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Inspiration CC</h2>
          <p>
            Inspiration Consolidated Consulting (ICC), LLC is a Women and
            Veteran-Owned Small Business with a track record of providing
            quality consulting, project management and operational services to
            clients throughout the DoD and civilian sector.
            <br />
            <br />
            Headquartered in Avondale, AZ, ICC supports government clients in
            several states and OCONUS, and is comprised of former military and
            government civilian personnel. ICC’s mission is to be a recognized
            leader applying innovative techniques in research and analysis.
            <br />
            <br />
            We create effective strategies for program design, execution, and
            sustainment while implementing action plans to meet our clients’
            goals. Navigating the complex world of inserting technology into the
            modern battlefield requires knowledge.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
					{/* <h2 className="major">Leadership</h2> */}
					<div className="profile-title" >
						<span className="image main">
							<img src={ssProfile} alt="" />
						</span>
						<div>
							<h2>Ms. Shannon O’Meara Smith</h2>
							<h3>President</h3>
						</div>
					</div>
          <p>
            President and co-founder of Inspiration Consolidated Consulting,
            LLC, Ms. Smith has over 24 years of experience in the Military and
            Corporate Intelligence fields providing critical support to
            organizations around issues of organizational health and market
            growth.
            <br />
            <br />
            Known for her analytical mindset as well as a deep interest in how
            the industry environment impacts revenues and market growth
            patterns, she never fails to display creativity and drive, and an
            ability to find industry information that others can’t. Core to her
            capabilities is the ability not just to conduct the research, but to
            extract the information that helps a company make the best business
            decisions — transforming data and research into specific
            recommendations, and persuasively representing that work to clients.
            <br />
            <br />
            Ms. Smith is well-versed in a number of diverse technology and
            market areas. These include, but are not limited to: cyber security,
            tactical and satellite communications, intelligence and surveillance
            systems, big data analytics, business model transformation and
            focused international growth.
            <br />
            <br />
            Ms. Smith has worked for a number of large Aerospace and Defense
            technology companies. Her responsibilities have included business
            forecasting, strategic planning, competitive landscaping, customer
            trends, and market analyses in support of mergers and acquisition
            and long term strategic planning.
            <br />
            <br />
            She has also served on active and reserve duty with both the U.S.
            Army and the U.S. Navy. Her last active duty assignment (2006-2007)
            was with an Explosive Ordnance Disposal unit located at COB
            Speicher, Iraq. Shannon is a career intelligence professional with
            over 17 years of experience in a multitude of intelligence
            specialties; primarily imagery intelligence, electronics
            intelligence, and All-Source Analysis. While serving in support of
            joint commands she also focused on Information Operations and
            Information Warfare. Her personal awards include the Meritorious
            Service Medal and the Joint Service Achievement Medal.
            <br />
            <br />
            Invested in helping her community to continue to thrive and grow,
            Ms. Smith is also an active commissioner on her hometown’s Municipal
            Arts Committee and Neighborhood and Family Services Commission.
            <br />
            <br />
          </p>
					<hr></hr>
					<div className="profile-title">
						<span className="image main">
							<img src={gsProfile} alt="" />
						</span>
						<div>
							<h2>Mr. Gary C Smith</h2>
							<h3>Vice President</h3>
						</div>
					</div>
          <p>
            Vice President and co-founder of Inspiration Consolidated
            Consulting. Mr. Smith has over 35 years experience in government,
            managing a broad spectrum of tasks, functions, processes and systems
            from concept to implementation and maintenance of organizations and
            materiel solutions.
            <br />
            <br />
            Mr. Smith’s proven capabilities include an excellent working
            knowledge of federal organizational structures, buying influences
            and processes, and MAS/GSA Schedule sales. He possesses an in-depth
            understanding of federal terminology, agencies, processes and
            programs, and the ability to analyze customer needs and craft
            services and solutions to meet those needs.
            <br />
            <br />
            Through solid existing relationships with Government Clients, Mr.
            Smith as demonstrated the ability to act in a consultative role to
            educate customers on the value proposition of chosen solutions in
            meeting the Government’s Information Technology needs. Mr. Smith has
            the experience to work in a matrixed environment with both Business
            Development and capture teams to engage the appropriate resources to
            ensure revenue and customer satisfaction goals are met.
            <br />
            <br />
            Mr. Smith has extensive experience in Navy Expeditionary, special
            operations, information operations, requirements assessment,
            Department of Defense acquisition and program management. Prior to
            retiring after a 27 year career in the United States Navy, Mr. Smith
            served as Assistant Chief of Staff Officer for Information
            Technology and was directly responsible for all information
            technology operations and training and led several large command and
            control (C2) and mission planning acquisition programs.
            <br />
            <br />
            Mr. Smith graduated with a Bachelor of Science Degree in Information
            Systems Management from National-Louis University in Washington, DC.
          </p>
          {close}
        </article>

        <article
          id="services"
          className={`${this.props.article === 'services' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Services</h2>

          <h3>System Requirements Analysis</h3>
          <p>
            Structured approach to translating the users need into a technical
            definition of the system.
          </p>

          <h3>Acquisition Lifecycle Management</h3>
          <p>
            Encompassing system’s life cycle, including acquisition,
            sustainment, and disposal.
          </p>

          <h3>Market Research</h3>
          <p>
            Full research analysis reports laying the foundation for your
            business’ marketing strategy decisions. Industry research is an
            evolving process with new information and trends developing every
            day.
          </p>

          <h3>Competitive Analysis</h3>
          <p>
            Critical part of your company marketing plan. With this evaluation,
            you can establish what makes your product or service unique–and
            therefore what attributes you play up in order to attract your
            target market.
          </p>

          {close}
        </article>
      </div>
    )
  }
}



export default Main
